<template>
  <v-list-item v-if="value" v-bind="$attrs" v-on="{ ...$listeners }">
    <v-list-item-avatar color="grey lighten-5">
      <v-icon>{{
        value.instName ? "mdi-factory" : "mdi-account-multiple"
      }}</v-icon>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title v-if="value.instName">
        {{ value.instName }}
      </v-list-item-title>
      <v-list-item-title v-else>
        Familie {{ value.lastName1 }}
      </v-list-item-title>

      <v-list-item-subtitle v-if="value">
        <span v-if="value && value.firstName1"
          >{{ value.firstName1 }}
          {{ value.lastName1 }}
        </span>
        <span v-if="value && value.firstName2">
          {{ value.firstName2 }}
          {{ value.lastName2 }}</span
        >
      </v-list-item-subtitle>
      <span
        >{{ value.town
        }}<span v-if="value.country">, {{ value.country }} </span></span
      >
    </v-list-item-content>
    <v-list-item-action><slot></slot></v-list-item-action>
  </v-list-item>
</template>

<script>
export default {
  props: ["value"],
};
</script>
