<template>
  <v-chip outlined v-bind="$attrs" v-on="{ ...$listeners }" v-if="value">
    <v-avatar left>
      <PortraitImage :value="value" />
    </v-avatar>
    <PersonName :value="value" :ext="!hideExt" :flags="flags" />
    <slot />
  </v-chip>
</template>
<script>
import PersonName from "common/components/PersonName";
import PortraitImage from "common/components/PortraitImage.vue";
export default {
  name: "PersonChip",
  components: {
    PersonName,
    PortraitImage,
  },
  props: {
    value: null,
    flags: { type: Boolean, default: false },
    hideExt: { type: Boolean, default: false },
  },
};
</script>
